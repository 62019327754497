import { EventNames } from '../enums';
import {
  ILocationChangeEventArgs,
  ILocationChangeMessageEvent,
} from '@desquare/interfaces';

export function createLocationEventPublisher(
  publisher: typeof window | typeof window.parent,
  targetUrl: string,
) {
  function publishOnLocationChange(data: ILocationChangeEventArgs) {
    publisher.postMessage(data, targetUrl);
  }

  return {
    publishOnLocationChange,
  };
}

export function createLocationEventSubscriber(
  subscriber: typeof window | typeof window.parent,
) {
  function addHandlerOnLocationEventChange(
    cb: (event: ILocationChangeMessageEvent) => any,
  ) {
    subscriber.addEventListener('message', (e: ILocationChangeMessageEvent) => {
      if (e.data.EventName === EventNames.ON_LOCATION_DETAILS_CHANGE) {
        cb(e);
      }
    });
  }

  return {
    addHandlerOnLocationEventChange,
  };
}
