// tslint:disable: no-any
import { EventNames } from '../enums';
import {
  IMapDragEndArgs,
  IMapDragEndMessageEvent,
  IMapDragMessageEvent,
  IMapReadyArgs,
  IMapReadyMessageEvent,
} from '@desquare/interfaces';

export function createMapEventPublisher(
  publisher: typeof window | typeof window.parent,
  targetUrl: string,
) {
  function publishOnMapDragEndArgs(data: IMapDragEndArgs) {
    publisher.postMessage(data, targetUrl);
  }

  function publishOnMapReady(data: IMapReadyArgs) {
    publisher.postMessage(data, targetUrl);
  }

  return {
    publishOnMapDragEndArgs,
    publishOnMapReady,
  };
}

export function createMapEventSubscriber(
  subscriber: typeof window | typeof window.parent,
) {
  function addHandlerOnMapDragEnd(cb: (event: IMapDragEndMessageEvent) => any) {
    subscriber.addEventListener('message', (e: IMapDragEndMessageEvent) => {
      if (e.data.EventName === EventNames.ON_MAP_DRAG_END) {
        cb(e);
      }
    });
  }

  function addHandlerOnMapDrag(cb: (event: IMapDragMessageEvent) => any) {
    subscriber.addEventListener('message', (e: IMapDragMessageEvent) => {
      if (e.data.EventName === EventNames.ON_MAP_DRAG) {
        cb(e);
      }
    });
  }

  function addHandlerOnMapReady(cb: (event: IMapReadyMessageEvent) => any) {
    subscriber.addEventListener('message', (e: IMapReadyMessageEvent) => {
      if (e.data.EventName === EventNames.ON_MAP_READY) {
        cb(e);
      }
    });
  }
  return {
    addHandlerOnMapDragEnd,
    addHandlerOnMapDrag,
    addHandlerOnMapReady,
  };
}
